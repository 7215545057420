import { useEffect, useRef } from 'react';

export default function useIdleTimer(callback, delay) {
  const timeoutIdRef = useRef(null);

  const resetTimer = () => {
    if (timeoutIdRef.current) {
      clearTimeout(timeoutIdRef.current);
    }
    timeoutIdRef.current = setTimeout(callback, delay);
  };

  useEffect(() => {
    resetTimer();
    return () => clearTimeout(timeoutIdRef.current);
  }, [callback, delay]);

  return resetTimer;
}
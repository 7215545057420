export default function GoogleFormOrder() {
    return (
      <div
        style={{
          height: "2780px",
          width: "100%",
          overflow: "hidden",
        }}
      >
        <iframe
          src="https://docs.google.com/forms/d/e/1FAIpQLSez4WY1Gz87f70FnnQjPqAn7_IPukxHh2PdtKCWgruly12NEA/viewform"
          width="100%"
          height="100%"
          frameborder="0"
          marginheight="0"
          marginwidth="0"
        >
          Loading…
        </iframe>
      </div>
    );
  }

import axios from "axios";

const api = axios.create({
  baseURL: "/api",
  headers: {
    "Content-Type": "application/json",
  },
});

export const createOrder = async (orderData) => {
  try {
    const res = await api.post("/create-order", { items: orderData });
    return res.data.id;
  } catch (error) {
    console.log(error);
  }
};

export const capturePaypalOrder = async (
  data,
  projectName,
  firstName,
  lastName,
  email,
  phone,
  specialRequest,
  order
) => {
  try {
    const res = await api.post("/capture-paypal-order", {
      orderID: data.orderID,
      projectName: projectName,
      firstName: firstName,
      lastName: lastName,
      email: email,
      phone: phone,
      notes: specialRequest,
      items: order,
    });
    return res;
  } catch (error) {
    console.error("Error capturing PayPal order:", error.response.data.error);
  }
};

export const clientOrderLogin = async (password) => {
  try {
    const res = await api.post("/special-auth", {
      password: password,
    });
    if (res.status === 200) {
      return true;
    }
    return false;
  } catch (err) {
    return false;
  }
};

export const createClientOrder = async (
  projectName,
  businessName,
  firstName,
  lastName,
  email,
  phone,
  notes,
  order
) => {
  const res = await api.post("/special-auth-order", {
    projectName: projectName,
    businessName: businessName,
    firstName: firstName,
    lastName: lastName,
    email: email,
    phone: phone,
    notes: notes,
    items: order,
  });
  return res;
};

import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App.jsx";
import reportWebVitals from "./reportWebVitals";
import OrderPage from "./pages/OrderPage";
import Navbar from "./layout/Navbar";
import Home from "./pages/Home";
import { ChakraProvider, CSSReset } from "@chakra-ui/react";
// import App from "./App.jsx";
const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    {/* <OrderPage /> */}
    <ChakraProvider resetCSS>
      {/* <Navbar></Navbar>
      <Home /> */}
      {/* <Navbar /> */}
      <App />
    </ChakraProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

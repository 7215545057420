import React, { useState, useCallback } from "react";
import {
  Box,
  Flex,
  IconButton,
  Image,
  HStack,
  Circle,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalCloseButton,
  ChakraProvider,
  extendTheme
} from "@chakra-ui/react";
import IdleTimer from "../../helper/IdleTimer";
import { motion, useAnimation, AnimatePresence } from "framer-motion";
import { ChevronLeftIcon, ChevronRightIcon } from "@chakra-ui/icons";


function Carousel({ images }) {
  const autoCycleDelay = 5000; // Time to auto-cycle in milliseconds
  const idleDelay = 15000; // Time to resume auto-cycling after inactivity in milliseconds

  const resetIdleTimer = IdleTimer(() => {
    goNext();
  }, autoCycleDelay);

  const handleUserInteraction = useCallback(() => {
    resetIdleTimer();
  }, [resetIdleTimer]);

  const [activeIndex, setActiveIndex] = useState(0);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const goNext = () => {
    setActiveIndex((prevIndex) => (prevIndex + 1) % images.length);
  };

  const goPrev = () => {
    setActiveIndex(
      (prevIndex) => (prevIndex - 1 + images.length) % images.length
    );
  };

  const openModal = () => {
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  const fadeAnimation = useAnimation();
  //   const MotionImage = motion.custom(Box);

  const goNextWithAnimation = async () => {
    await fadeAnimation.start({ opacity: 0 });
    goNext();
    fadeAnimation.start({ opacity: 1 });
  };

  const goPrevWithAnimation = async () => {
    await fadeAnimation.start({ opacity: 0 });
    goPrev();
    fadeAnimation.start({ opacity: 1 });
  };

  const aspectRatio = 0.5;

  const theme = extendTheme({
    breakpoints: {
      sm: "30em",
      custom: "1400px", // Corresponds to 1024px
      md: "48em",
      lg: "62em",
      xl: "80em",
      "2xl": "96em",
    },
  });

  return (
    <ChakraProvider theme={theme}>

      <Box pb={4}>
        {/* Carousel */}
        <Box position="relative" width={{ base: "90vw", md: '90%', custom:"650px", lg: "500px" }} mx="auto">
        <Flex
          position="relative"
          width="100%"
          rounded="md"
          marginTop={12}
          onMouseEnter={handleUserInteraction}
          onMouseLeave={handleUserInteraction}
        >
          <IconButton
            icon={<ChevronLeftIcon />}
            //   onClick={goPrev}
            onClick={goPrevWithAnimation}
            aria-label="Previous image"
            position="absolute"
            left="0"
            top="50%"
            transform="translateY(-50%)"
            zIndex="1"
            variant="ghost"
            fontSize={40}
            textColor="white"
            height="100%"
            bg="rgba(0, 0, 0, 0.10)"
            w="13%"
            _hover={{ bg: "rgba(0, 0, 0, 0.50)" }}
          />
          <Box
            position="relative"
            width={{ base: "100%", md: "800px" }}
            paddingBottom={`${aspectRatio * 100}%`}
            overflow="hidden"
          >
            <AnimatePresence>
              {images.map((src, index) => (
                <motion.img
                  key={index}
                  loading="lazy"
                  src={process.env.PUBLIC_URL + "/images/" + src}
                  onClick={openModal}
                  cursor="pointer"
                  alt={`Carousel image ${index + 1}`}
                  initial={{ opacity: 0 }}
                  animate={{
                    opacity: index === activeIndex ? 1 : 0,
                  }}
                  exit={{ opacity: 0 }}
                  transition={{ duration: 0.5 }}
                  style={{
                    position: "absolute",
                    top: 0,
                    left: 0,
                    width: "100%",
                    height: "100%",
                    borderRadius: "15px",
                    cursor: "pointer",
                    objectFit: "cover",
                  }}
                />
              ))}
            </AnimatePresence>
          </Box>
          <IconButton
            icon={<ChevronRightIcon />}
            onClick={goNextWithAnimation}
            aria-label="Next image"
            position="absolute"
            right="0"
            top="50%"
            transform="translateY(-50%)"
            zIndex="1"
            variant="ghost"
            fontSize={40}
            textColor="white"
            height="100%"
            bg="rgba(0, 0, 0, 0.10)"
            w="13%"
            marginLeft="13%"
            _hover={{ bg: "rgba(0, 0, 0, 0.50)" }}
            />
        </Flex>
            </Box>
        <HStack justify="center" spacing={2} marginTop={4}>
          {images.map((_, index) => (
            <Circle
              key={index}
              size="8px"
              bg={index === activeIndex ? "blue.500" : "gray.300"}
            />
          ))}
        </HStack>

        {/* <Modal isOpen={isModalOpen} onClose={closeModal} size="full">
          <ModalOverlay bg="rgba(0, 0, 0, 0.75)" />
          <ModalContent
            backgroundColor="transparent"
            boxShadow="none"
            justifyContent="center"
            w="90vw"
          >
            <ModalCloseButton color="white" />
            <Flex position="relative" height="100%">
              <IconButton
                icon={<ChevronLeftIcon />}
                onClick={goPrev}
                aria-label="Previous image"
                position="absolute"
                left="0"
                top="50%"
                transform="translateY(-50%)"
                zIndex="1"
                variant="ghost"
                colorScheme="whiteAlpha"
                fontSize="8xl"
                height="60vh"
              />
              <Image
                src={process.env.PUBLIC_URL + "/images/" + images[activeIndex]}
                alt={`Carousel image ${activeIndex + 1}`}
                objectFit="contain"
                maxHeight="90vh"
                margin="auto"
                maxW="80%"
              />
              <IconButton
                icon={<ChevronRightIcon />}
                onClick={goNext}
                aria-label="Next image"
                position="absolute"
                right="0"
                top="50%"
                transform="translateY(-50%)"
                zIndex="1"
                variant="ghost"
                colorScheme="whiteAlpha"
                fontSize="8xl"
                height="60vh"
                //   rounded="full"
              />
            </Flex>
          </ModalContent>
        </Modal> */}
      </Box>
    </ChakraProvider>
  );
}

export default Carousel;

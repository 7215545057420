import Home from "./pages/Home";
import GeneralGoogleForm from "./components/GeneralGoogleForm"
import OrderPage from "./pages/OrderPage"
import OrderSuccess from "./pages/OrderSuccess"
import SpecialOrder from "./pages/SpecialOrder";
import Navbar from "./layout/Navbar"
import { BrowserRouter as Router, Route, Routes, Navigate } from "react-router-dom"
import { ChakraProvider } from "@chakra-ui/react";
import { CSSReset } from "@chakra-ui/react";

export default function App() {
    return (
        <Router>
            <ChakraProvider>
                <CSSReset />
                <Navbar />
                <Routes>
                    {/* <Route path="/photomont" element={<Navigate to="/"/>}/> */}
                    <Route path="/" element={<Home />} />
                    {/* <Route path="/order" element={<OrderPage />} /> */}
                    <Route path="/order" element={<GeneralGoogleForm />} />

                    <Route path="/orderSuccess" element={<OrderSuccess />} />
                    <Route path="/client-order" element={<SpecialOrder />} />
                </Routes>
            </ChakraProvider>
        </Router>
    )
}
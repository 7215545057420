import {
    Flex,
    Container,
    Heading,
    Stack,
    Text,
    Button,
    Box,
    Icon,
    IconProps,
    extendTheme,
    ChakraProvider
  } from '@chakra-ui/react';
  import Order from '../components/Order'
  import { useRef } from 'react';
  export default function CallToActionWithIllustration() {
  
    const ref = useRef(null);
  
    const handleClick = () => {
      ref.current?.scrollIntoView({behavior: 'smooth'});
      console.log('here')
    };
  
    const theme = extendTheme({
      fonts: {
        heading: "Montserrat",
        body: "Roboto",
        mono: "Playfair Display",
      },
      breakpoints: {
        sm: "30em",
        custom: "1200px",
        customContainer: "772px",
        md: "48em",
        lg: "62em",
        xl: "80em",
        "2xl": "96em",
      },
    });
  
    return (
      <ChakraProvider 
      theme={theme}
      >
        <Box bgGradient="linear(to-b, white, gray.200)" pb={{md:20}} h="100vh">
          <Container maxW={'100%'} display='flex' justifyContent='center' alignContent='center'>
            <Stack
              textAlign={'center'}
              align={'center'}
              spacing={{ base: 8, md: 10 }}
              pt={{ base: 20, md: 28 }}>
              <Heading
                fontWeight={600}
                fontSize={{ base: '3xl', sm: '4xl', md: '6xl' }}
                fontFamily="Montserrat"
                lineHeight={'110%'}>
                <Text as={'span'} 
                // color={'orange.400'}
                >
                Thank you for your purchase!
                </Text>
              </Heading>
              <Text color={'gray.500'} maxW={'3xl'}>
                The Dropbox has opened in another tab. You can also access the Dropbox using the link provided in your invoice
              </Text>
              {/* <Stack spacing={6} direction={'row'}>
                <Button
                  rounded={'full'}
                  px={6}
                  colorScheme={'orange'}
                  bg={'orange.400'}
                  _hover={{ bg: 'orange.500' }}
                  onClick={handleClick}>
                  Get started
                </Button>
                <Button rounded={'full'} px={6}>
                  How it works
                </Button>
              </Stack> */}
              {/* <Flex w={'full'} justifyContent='center'>
                <iframe 
                    src="https://www.dropbox.com/sh/d1mh3ieett8801y/AAAlNKNXv1ovLi6jrtK60tVga?dl=0"
                    frameBorder="0" 
                    width="100%" 
                    height="480" 
                    allowFullScreen="true"
                ></iframe>
              </Flex> */}
            </Stack>
          </Container>
        </Box>
      </ChakraProvider>
    );
  }
import React from "react";
import {
  ChakraProvider,
  Box,
  Flex,
  Heading,
  Text,
  Button,
  Container,
  extendTheme,
  Divider,
  Link,
  SimpleGrid,
} from "@chakra-ui/react";
import {
  FaRocket,
  FaGift,
  FaHeadset,
  FaPencilAlt,
  FaBan
} from "react-icons/fa";
import Carousel from "../components/Home/Carousel";
import Fonts from "../theme/Font";
import WhyUsReason from "../components/Home/WhyUsReason";

const theme = extendTheme({
  fonts: {
    heading: "Montserrat",
    body: "Roboto",
    mono: "Playfair Display, serif",
  },
  styles: {
    global: {
      body: {
        color: "gray.600",
        lineHeight: "tall",
      },
      h1: {
        fontFamily: "Montserrat, sans-serif",
        fontWeight: "bold",
        fontSize: { base: "2xl", md: "4xl" },
        marginBottom: "1em",
      },
      p: {
        fontFamily: "Roboto, sans-serif",
        fontSize: { base: "sm", md: "md" },
      },
    },
    breakpoints: {
      sm: "30em",
      custom: "1200px",
      customContainer: "772px",
      md: "48em",
      lg: "62em",
      xl: "80em",
      "2xl": "96em",
    },
  },
});

export default function Home() {
  const hdrFlamImgs = [
    "hdr_flambient/hdr_flam1.jpg",
    "hdr_flambient/hdr_flam2.jpg",
    "hdr_flambient/hdr_flam3.jpeg",
    "hdr_flambient/hdr_flam4.jpeg",
    "hdr_flambient/hdr_flam5.jpg",
  ];

  const singleExposureImgs = [
    "single_exposure/single1.jpg",
    "single_exposure/single2.jpg",
    "single_exposure/single3.jpg",
    "single_exposure/single4.jpg",
    "single_exposure/single5.jpg",
  ];

  const virtualTwilightImgs = [
    "virtual_twilight/vt1.jpg",
    "virtual_twilight/vt2.jpg",
    "virtual_twilight/vt3.jpg",
    "virtual_twilight/vt4.jpg",
  ];

  const floorplanImgs = [
    "floorplans/floor1.jpg",
    "floorplans/floor2.jpg",
    "floorplans/floor3.jpg",
    "floorplans/floor4.jpg",
  ];

  const renoImgs = [
    "staging_renos/reno1.jpg",
    "staging_renos/reno2.jpg",
    "staging_renos/reno3.jpg",
    "staging_renos/reno4.png",
  ];

  const virtualRemovalImgs = [
    "virtual_removals/removal1before.jpg",
    "virtual_removals/removal1after.jpg",
    "virtual_removals/removal2before.jpg",
    "virtual_removals/removal2after.jpg",
  ];

  const reasons = [
    { title: "Free Trial", desc: "Get a free combo of 3 HDR + 1 day to dusk", icon: FaGift },
    { title: "Fast", desc: "18 hour turn around time", icon: FaRocket },
    { title: "Unlimited Revisions", desc: "100% customer satisfaction guarantee", icon: FaPencilAlt },
    { title: "No Subscriptions", desc: "Pay only for what you need", icon: FaBan },
  ]

  return (
    <ChakraProvider theme={theme}>
      <Fonts />
      <Box>
        <Box
          as="header"
          bg="black"
          minHeight="80vh"
          display="flex"
          alignItems="center"
          justifyContent="center"
          backgroundImage={process.env.PUBLIC_URL + "/images/cover_comp.webp"}
          backgroundPosition="center center"
          backgroundSize="cover"
          // roundedBottom="xl"
          boxShadow="2xl"
        >
          <Container maxW="container.sm" textAlign="center">
            <Heading
              as="h1"
              size={{ base: "3xl", sm: "4xl" }}
              color="black"
              letterSpacing="wide"
              fontFamily="Montserrat"
            >
              Photomont
            </Heading>
            <Text
              as="h2"
              fontSize="3xl"
              color="black"
              mt={4}
              mb={8}
              fontFamily="Montserrat"
            >
              Transforming ordinary property photos into extraordinary first
              impressions - Let us enhance your real estate visuals today!
            </Text>
          </Container>
        </Box>

        <Box textAlign="center" bgGradient="linear(to-r, white, gray.200)">
          <Flex
            bgGradient="linear(to-r, gray.600, gray.800)"
            h="200px"
            justify="center"
            align="center"
          >
            <Heading as="h2" size="3xl"
              color="white"
            >
              Why Choose Us?
            </Heading>
          </Flex>

          <Container
            maxW={{ md: "container.sm", customContainer: "container.base" }}
          >
            <Flex padding={50} gap={10} flexWrap="wrap" justify='center'>
              {reasons.map(reason => (
                <WhyUsReason key={reason.title} title={reason.title} desc={reason.desc} icon={reason.icon} />
              ))}
            </Flex>
          </Container>
        </Box>

        <Box
          as="section"
          id="about"
          textAlign="center"
          bgGradient="linear(to-r, white, gray.200)"
        >
          <Flex
            bgGradient="linear(to-r, gray.600, gray.800)"
            h="200px"
            justify="center"
            align="center"
          >
            <Heading as="h2" size="3xl" color="white">
              Services
            </Heading>
          </Flex>
          <Container
            maxW={{ md: "container.sm", customContainer: "container.base" }}
          >
            <SimpleGrid
              columns={{ md: 1, lg: 2 }}
              spacingX={{ custom: 600, lg: 450 }}
              placeItems="center"
              marginTop={4}
            >
              <Heading
                as="h2"
                fontSize={{ sm: "5xl", base: "4xl" }}
                mt={50}
                textAlign="center"
                display={{ md: "block", lg: "none" }}
              >
                HDR & Flambient Editing
              </Heading>
              <Carousel images={hdrFlamImgs} />
              <Heading
                as="h2"
                fontSize="5xl"
                textAlign="left"
                display={{ base: "none", lg: "block" }}
              >
                HDR & <br></br> Flambient Editing
              </Heading>

              <Heading
                as="h2"
                mt={50}
                fontSize={{ md: "5xl", sm: "5xl", base: "4xl" }}
                textAlign={{ base: "center", lg: "left" }}
              >
                Single Exposure Editing
              </Heading>
              <Carousel images={singleExposureImgs} />

              <Heading
                as="h2"
                fontSize={{ sm: "5xl", base: "4xl" }}
                mt={8}
                textAlign="center"
                display={{ md: "block", lg: "none" }}
              >
                Virtual Twilight
              </Heading>
              <Carousel images={virtualTwilightImgs} />
              <Heading
                as="h2"
                fontSize="5xl"
                textAlign="left"
                display={{ base: "none", lg: "block" }}
              >
                Virtual Twilight
              </Heading>

              <Heading
                as="h2"
                mt={50}
                fontSize={{ md: "5xl", sm: "5xl", base: "4xl" }}
                textAlign={{ base: "center", lg: "left" }}
              >
                2D & 3D <Text display={{ base: "none", lg: "block" }}></Text>{" "}
                Floorplans
              </Heading>
              <Carousel images={floorplanImgs} />

              <Heading
                as="h2"
                fontSize={{ sm: "5xl", base: "4xl" }}
                mt={8}
                textAlign="center"
                display={{ md: "block", lg: "none" }}
              >
                Virtual & Staging Renovations
              </Heading>
              <Carousel images={renoImgs} />
              <Heading
                as="h2"
                fontSize="5xl"
                textAlign="left"
                display={{ base: "none", lg: "block" }}
              >
                Virtual & <br></br> Staging Renovations
              </Heading>

              <Heading
                as="h2"
                mt={50}
                fontSize={{ md: "5xl", sm: "5xl", base: "4xl" }}
                textAlign={{ base: "center", lg: "left" }}
              >
                Virtual Removals
              </Heading>
              <Carousel images={virtualRemovalImgs} />
            </SimpleGrid>
          </Container>
        </Box>
        <SimpleGrid columns={{ lg: 2, md: 1 }}>
          <Flex
            bgGradient="linear(to-r, gray.600, gray.800)"
            justify="center"
            align="center"
            h={{ lg: "auto", base: "300px" }}
          >
            <Heading as="h2" size="3xl" color="white" fontFamily="Montserrat">
              Our Story
            </Heading>
          </Flex>
          <Flex
            justify="center"
            align="center"
            bgGradient="linear(to-r, white, gray.200)"
          >
            <Text
              align="left"
              width={{ md: "80%", sm: "100%" }}
              fontSize={{ md: "lg", sm: "xl" }}
              p="8"
              fontFamily="Montserrat"
            >
              At Photomont, we understand the importance of visually stunning
              imagery when it comes to selling or showcasing properties. With
              years of experience in the industry, our team of skilled
              professionals has a keen eye for capturing the unique essence of
              each property and highlighting its best features through expert
              photo editing techniques. Whether you're a real estate agent,
              photographer, or homeowner, we strive to provide exceptional
              service and exceptional results, ensuring that your property
              stands out in a crowded market. Trust us to take your property to
              the next level with our unparalleled expertise in real estate
              photography and photo editing.
            </Text>
          </Flex>
        </SimpleGrid>
        <hr></hr>
        <Flex
          p={8}
          bgGradient="linear(to-r, white, gray.200)"
          justify="center"
          align="center"
          h={{ lg: "auto", base: "110px" }}
        >
          <Heading as="h2" size="xl" fontFamily="Montserrat">
            Contact Us
          </Heading>
        </Flex>
        <Flex
          justify="center"
          align="center"
          bgGradient="linear(to-r, white, gray.200)"
          direction="column"
        >
          <Text
            align="center"
            width={{ md: "80%", sm: "100%" }}
            fontSize={{ md: "lg", sm: "xl" }}
            fontFamily="Montserrat"
            mb={{ base: 8, md: 8 }}
          >
            Have question or concern? <br></br>Contact us at
            <Link>
              <a href="mailto:hello@photomont.com"> hello@photomont.com</a>
            </Link>
          </Text>
          <Text
            align="center"
            width={{ md: "80%", sm: "100%" }}
            fontSize={{ md: "lg", sm: "xl" }}
            fontFamily="Montserrat"
            mb={{ base: 8, md: 8 }}
          >
            Customer Support:
            <Link>
              <a href="mailto:support@photomont.com"> support@photomont.com</a>
            </Link>
          </Text>
          <Link href="mailto:hello@photomont.com">
            <Button variant="solid" colorScheme="twitter" rounded="full" mb={4}>
              Send us an Email
            </Button>
          </Link>
        </Flex>
      </Box>
    </ChakraProvider>
  );
}

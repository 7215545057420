import {
  Box,
  Button,
  Flex,
  HStack,
  Image,
} from "@chakra-ui/react";
import { Link } from "react-router-dom";
import { useLocation } from "react-router-dom";

export default function App() {
  const location = useLocation();
  const currentPage = location.pathname;

  return (
    <Box as="section" position="sticky" top={0} zIndex={9999}>
      <Box
        as="nav"
        bg="bg-surface"
        boxShadow="md"
        bgGradient="linear(to-r, white, gray.200)"
      >
        <Box py={{ base: "4", lg: "5" }}>
          <HStack
            spacing={{ md: 200, base: 10 }}
            display="flex"
            justify="center"
          >
            <Image
              src={process.env.PUBLIC_URL + "/images/logo.png"}
              h={{ md: "70px", base: "50px" }}
              aspectRatio={16 / 9}
            />
            <Flex justify="space-between">
              <HStack variant="link" spacing={{ md: 16, base: 4 }}>
                <Link to="/">
                  <Button
                    colorScheme="twitter"
                    variant={currentPage === "/photomont" || currentPage === "/" ? "solid" : "outline"}
                    size={{ md: "md", base: "sm" }}
                    onClick={() => {}}
                  >
                    Home
                  </Button>
                </Link>
                <Link to="/order">
                  <Button
                    colorScheme="twitter"
                    variant={currentPage === "/order" ? "solid" : "outline"}
                    size={{ md: "md", base: "sm" }}
                    onClick={() => {}}
                  >
                    Order
                  </Button>
                </Link>
                <Link to="/client-order">
                  <Button
                    colorScheme="twitter"
                    variant={currentPage === "/client-order" ? "solid" : "outline"}
                    size={{ md: "md", base: "sm" }}
                    onClick={() => {}}
                  >
                    Client Order
                  </Button>
                </Link>
              </HStack>
            </Flex>
          </HStack>
        </Box>
      </Box>
    </Box>
  );
}

import {
    Box,
    Flex,
    Card,
    Heading,
    Text
} from "@chakra-ui/react"
import { Icon } from '@chakra-ui/react'
export default function WhyUsReason(props) {
    return (
        <Card w="300px" h="250" boxShadow="xl" _hover={{boxShadow:"2xl"}} px={4}>
            <Flex direction="column" justify="center" align="center" pt={5} gap={6}>
                <Icon as={props.icon} fontSize="4xl" color="#0B95FE"></Icon>
                <Heading as="h2" fontSize="3xl">
                    {props.title}
                </Heading>
                <Text>
                    {props.desc}
                </Text>
            </Flex>
        </Card>
    )
}
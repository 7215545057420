import { useState } from "react";
import { Link } from "react-router-dom";
import Order from "../components/Order";
import GoogleFormOrder from "../components/GoogleFormOrder";
import { clientOrderLogin } from "../api/api";
import {
  Button,
  Input,
  Flex,
  FormControl,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  FormErrorMessage,
  FormLabel,
} from "@chakra-ui/react";

export default function SpecialOrder() {
  const [passWordCorrect, setPasswordCorrect] = useState(false);
  const [password, setPassword] = useState("");
  const [passwordInvalid, setPasswordInvalid] = useState(false);
  const [passWordErrorMsg, setPasswordErrorMsg] = useState("");

  const updatePassword = (updatedPassword) => {
    if (passwordInvalid) {
      setPasswordInvalid(false);
    }
    setPassword(updatedPassword);
  };

  const checkPassword = async () => {
    if (password.length === 0) {
      setPasswordInvalid(true);
      setPasswordErrorMsg("Password cannot be empty.");
      return;
    }
    const valid = await clientOrderLogin(password)
    if (valid) {
      setPasswordInvalid(false);
      setPasswordCorrect(true);
    } else {
      setPasswordInvalid(true);
      setPasswordErrorMsg("Incorrect password.");
    }
  };

  return (
    <>
      {!passWordCorrect ? (
        <Modal isOpen={!passWordCorrect} size="md" isCentered>
          <ModalOverlay />
          <ModalContent>
            <ModalHeader>Enter Client Order Password</ModalHeader>
            <ModalBody>
              <FormControl isInvalid={passwordInvalid} onSubmit={checkPassword}>
                <FormLabel>Password</FormLabel>
                <Input
                  placeholder="Password"
                  type="password"
                  value={password}
                  onChange={(e) => updatePassword(e.target.value)}
                />
                <FormErrorMessage>{passWordErrorMsg}</FormErrorMessage>
              </FormControl>
            </ModalBody>
            <ModalFooter>
              <Button colorScheme="twitter" onClick={checkPassword}>
                Continue
              </Button>
              <Link to="/">
                <Button
                  variant="ghost"
                  onClick={() => {
                    setPassword("");
                  }}
                >
                  Cancel
                </Button>
              </Link>
            </ModalFooter>
          </ModalContent>
        </Modal>
      ) : (
        <Flex justifyContent="center">
          <GoogleFormOrder/>
          {/* <Order clientOrder={true} /> */}
        </Flex>
      )}
    </>
  );
}
